.container {
  position: relative;
  height: 175px;
  pointer-events: none;
}

.container svg {
  top: -16px;
  left: 50%;
  width: 300px;
  transform: translateX(-50%);
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
}

.icon svg {
  position: absolute;
  width: 70px;
  top: -92px;
}

@media screen and (min-width: 450px) {
  .container {
    height: 200px;
  }

  .container svg {
    top: -56px;
    width: 435px;
  }

  .icon svg {
    position: absolute;
    width: 91px;
    top: -100px;
  }
}
