.modal {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  z-index: 10;
  color: #000;
  text-align: left;
}

.backdrop {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.4);
}

.content {
  background-color: var(--color-white);
  max-width: 500px;
  max-height: 100vh;
  overflow: auto;
  width: 90%;
  padding: 20px;
  border-radius: 8px;
}


.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.modalHeader button {
  background-color: transparent;
  border: none;
}

.modalHeader button svg {
  color: var(--color-green);
  font-size: 18px;
}