.ctacontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 40px;
  height: 100%;
  max-width: 100%;
}

.ctacontainer p {
  line-height: 24px;
}

@media screen and (min-height: 800px) {
  .ctacontainer {
    gap: 50px;
  }
}

@media screen and (min-width: 600px) {
  .ctacontainer {
    gap: 70px;
  }

  .email {
    gap: 30px;
  }

  .movesta {
    gap: 30px;
  }
}

.email {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.emailform {
  display: flex;
  justify-content: center;
  gap: 16px;
  max-width: 600px;
  width: 100%;
  margin-inline: auto;
}

.movesta {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.movestalinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.emailform {
  position: relative;
  padding: 0;
}

.forminput {
  background: #ffffff;
  border-radius: 7px;
  border: 2px solid #ffffff;
  padding: 16px;
  flex: 3 1 0px;
  font-size: 18px;
  margin: 0;
}

.formbutton {
  text-decoration: none;
  border: 2px solid #ffffff;
  border-radius: 7px;
  background: transparent;
  font-size: 18px;
  color: white;
  padding: 16px 30px;
  flex: 1 1 0px;
  margin: 0;
}

@media screen and (max-width: 500px) {
  .emailform .formbutton {
    position: absolute;
    background-color: var(--color-green);
    border: none;
    top: 2px;
    bottom: 2px;
    right: 2px;
    width: 65px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .forminput {
    padding-right: 80px;
  }
}

.formbutton span {
  display: none;
}

.formbutton svg {
  font-size: 28px;
}

@media screen and (min-width: 500px) {
  .formbutton span {
    display: inline;
  }

  .formbutton svg {
    font-size: 1em;
    margin-left: 1em;
    margin-right: -4px;
  }

  .formbutton svg:not(.spinning) {
    display: none;
  }
}

.formHelperText {
  margin-top: 8px;
  font-size: 12px;
}

.formHelperText a {
  color: inherit;
}

.footer {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px 12px;
}


@media screen and (min-height: 800px) {

  .footer {
    margin-top: 60px;
  }
}


@media screen and (min-height: 1000px) {
  .footer {
    margin-top: 100px;
  }
}

.footer > a,
.footer > button {
  white-space: nowrap;
  background: none;
  color: var(--color-white);
  border: none;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  padding: 0;
}

.footer > a:hover,
.footer > button:hover {
  text-decoration: underline;
}

.disclaimer {
  color: #000;
  text-align: left;
}

.disclaimer p {
  margin-block: 1em;
  line-height: 20px;
}
