.form {
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.formFieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

@media screen and (max-height: 750px) {
  .form header {
    margin-bottom: 26px;
  }
}

@media screen and (min-height: 750px) {
  .form {
    gap: 60px;
  }
  .formFieldsContainer {
    gap: 45px;
  }
}

@media screen and (min-height: 950px) {
  .form {
    gap: 70px;
  }
  .formFieldsContainer {
    gap: 60px;
  }
}

.form header {
  text-align: center;
}

.form header h1 {
  font-size: 9vw;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (min-width: 365px) {
  .form header h1 {
    font-size: 34px;
  }
}

.subtitle {
  display: inline-flex;
  flex-direction: row;
  font-size: 18px;
  gap: 8px;
}

.form header img {
  height: 18px;
}

.postalWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.postalInput {
  display: block;
  padding: 12px;
  color: var(--color-green);
  background-color: var(--color-white);
  width: 100%;
  text-align: center;
  border-radius: 7px;
}

.postalInputContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.postalInputContainer button {
  position: absolute;
  border: none;
  background-color: transparent;
  padding: 5px 15px;
  color: var(--color-green);
  font-size: 120%;
}

@media screen and (max-height: 750px) {
  .postalInput {
    padding: 5px;
  }
}

.fieldError {
  height: 0;
  overflow: visible;
}

.postalInput input {
  font-size: 2.5em;
  border: none;
  display: block;
  width: 100%;
  height: auto;
  color: inherit;
  text-align: inherit;
  border-radius: 0;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
}

.postalInput input:focus {
  outline: none;
}

.housingType {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.housingType > div {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 16px;
}

.housingType input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.housingType label {
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(50% - 8px);
  max-width: 250px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border: 2px solid white;
  border-radius: 7px;
  padding: 15px 10px;
  transition: background-color 200ms ease, color 200ms ease;
}


.housingType label:hover,
.housingType input:focus + label {
  background-color: rgba(255, 255, 255, 0.1);
}

.housingType input:focus + label:not(:active) {
  outline: none;
  background-color: rgba(255, 255, 255, 0.5);
}

.housingType input:checked + label {
  background-color: white !important;
  color: var(--color-green);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 37%);
}

.housingType svg {
  font-size: 40px;
}


@media screen and (max-height: 750px) {
  .housingType svg {
    font-size: 30px;
  }
  .housingType label {
    padding: 15px;
  }
}

.housingType p {
  margin-top: 5px;
}

.submitError {
  text-align: center;
}

.cta button {
  width: 100%;
  background: var(--color-white);
  border-radius: 4px;
  padding: 12px 10px;
  width: 100%;
  border: none;

  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-green);
}

.cta button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.cta svg {
  margin-left: 1em;
  margin-right: -4px; /* For visual balance */
  animation: spin 1s linear infinite;
}

.cta p {
  margin-top: 5px;
  margin-inline: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
