.container {
  display: flex;
  justify-content: center;
}

.container a {
  color: inherit;
}

.container a svg {
  font-size: 40px;
}

@media screen and (min-width: 1100px) {
  .container {
    justify-content: flex-end;
  }

  .container a svg {
    font-size: 60px;
    margin-right: -50px;
  }
}
