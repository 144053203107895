:root {
  --color-blue: #153450;
  --color-lightblue: #b4c7d1;
  --color-green: #2d7a72;
  --color-lightgreen: #2d7a7250;
  --color-white: #ffffff;
  --color-beige: #f8f6f5;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

:is(button, a):not(:disabled) {
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.container {
  padding-inline: 16px;
  padding-block: 48px;
  width: 100%;
  max-width: 1000px;
  margin-inline: auto;
}

.container.narrow {
  max-width: 400px;
}

.scroll-wrapper {
  height: 100vh;
  width: 100%;
  overflow: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.parallax-layer {
  background-color: var(--color-green);
  color: white;
  min-height: 100vh;
  position: relative;
  scroll-snap-align: start;
  display: flex;
  align-items: center;
}

.parallax-layer.blue {
  background-color: var(--color-blue);
}

.parallax-layer.beige {
  background-color: var(--color-beige);
}

.parallax-layer.lightblue {
  background-color: var(--color-lightblue);
  color: black;
}
.parallax-layer.lightgreen {
  background-color: rgba(45, 122, 114, 0.314);
}

.parallax-layer.auto-height {
  height: fit-content !important;
}

.floating-down-button {
  display: flex;
  justify-content: end;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 600px) {
  .container {
    padding-inline: 32px;
  }
}

.chart {
  min-height: 45vh;
  max-height: 50vw;
}

@media screen and (max-width: 450px) {
  .recharts-xAxis
    .recharts-cartesian-axis-tick:nth-child(n + 2):nth-last-child(n + 2) {
    display: none;
  }
}

@media screen and (min-height: 570px) {
  .chart {
    height: 320px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
    transform: 1;
  }
}

.next-section-button {
  margin-right: 20px;
  font-size: 28pt;
}

.recharts-cartesian-axis-tick {
  text-transform: capitalize;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
