.scrollProgressBar {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
}

.scrollProgressBar div {
  height: 5px;
  transition: width 0.3s 0.2s, background-color 0.2s;  
  border-bottom-right-radius: 5px;

}
