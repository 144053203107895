.fab {
  position: fixed;
  top: 80%;
  transform: translate(-50%) rotate(0.25turn);
  z-index: 10;
  transform-origin: center bottom;
  padding: 5px 20px;
  background-color: var(--color-white);
  opacity: 0.5;
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
}

@media screen and (max-height: 575px) {
  .fab {
    bottom: 90px;
    top: auto;
  }
}

@media (pointer: coarse) and (orientation: landscape) {
  .fab {
    padding-bottom: env(safe-area-inset-left);
  }
}

.fab:hover,
.fab:active {
  opacity: 1;
}

.content form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.content form div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content > button {
  background: none;
  border: none;
  color: var(--color-green);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 8px;
}

.content > button:hover {
  background-color: var(--color-lightgreen);
}

.content input,
.content textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #555;
}

.content input[type='submit'] {
  background-color: var(--color-green);
  border: none;
  color: var(--color-white);
  cursor: pointer;
}

.content input[type='submit']:hover {
  background-color: var(--color-blue);
}

.content textarea {
  resize: vertical;
  font-family: inherit;
}
