.wrapper {
  align-items: center;
  background-color: var(--color-blue);
  color: var(--color-white);
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 24px;
  justify-content: center;
  position: fixed;
  z-index: 100;
}

.wrapper button {
  color: inherit;
  border: 2px solid var(--color-white);
  background-color: transparent;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  margin-top: 24px;
  font-weight: bold;
}

.wrapper a {
  font-weight: bold;
  color: inherit;
}

@media screen and (min-width: 800px) {
  .wrapper div {
    display: flex;
    gap: 16px;
    max-width: 1000px;
  }
  .wrapper button {
    padding-inline: 80px;
    width: auto;
    margin-top: 0;
    transition: 0.2s background-color ease;
  }

  .wrapper button:hover {
    color: var(--color-blue);
    background-color: var(--color-white);
  }
}
