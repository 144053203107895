@media screen and (max-width: 430px) {
  .heading > span::before {
    content: '\A';
    white-space: pre;
  }
}

.heading span > div {
  display: inline-flex !important;
}
.heading span > div > div {
  text-align: center;
}
