.contentWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1em;
  margin-top: 20px
}

.scoretitle {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  text-align: left;
}

.scoretitletext {
  font-size: 28px;
  font-weight: 600;
}
.shareseparator {
  display: block;
  margin-bottom: -4px;
}

.shareseparator svg {
  height: 27px;
}

.sharebtn {
  font-size: 20px;
}

.sharebtn {
  background-color: transparent;
  border: none;
  padding: 10px 12px;
  border-radius: 50%;
  position: relative;
}

.sharebtn:hover {
  background-color: #eee;
}

.sharebtn svg {
  color: var(--color-green);
}

.sharebtntext {
  display: none;
}

.sharebtn div {
  position: absolute;
  top: 110%;
  background-color: var(--color-white);
  color: var(--color-blue);
  pointer-events: none;
  box-shadow: 0 4px 13px -3px rgb(0 0 0 / 10%);
  padding: 10px 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
}

@media screen and (min-width: 600px) {
  .scoretitle {
    gap: 25px;
  }
}

@media screen and (max-width: 340px) {
  .nedåtgående-trend,
  .uppåtgående-trend {
    font-size: calc(8.7vw - 6px);
  }
}

@media screen and (max-width: 410px) {
  .svagt-nedåtgående-trend,
  .svagt-uppåtgående-trend {
    font-size: calc(8.7vw - 12px);
  }
}

@media screen and (max-width: 325px) {
  .stillastående-trend {
    font-size: calc(12.5vw - 12px);
  }
}

@media screen and (max-width: 325px) {
  .stillastående-trend {
    font-size: calc(12.5vw - 12px);
  }
}
