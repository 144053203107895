.container svg {
  font-size: 2.5em;
}
.container h2 {
  margin-block: 16px;
}

@media screen and (min-width: 800px) {
  .container svg {
    font-size: 4em;
  }

  .container h2 {
    font-size: 1.75em;
  }
}
